import axios from 'axios';

axios.defaults.baseURL = process.env.GATSBY_API_URL;

export default {

  getSupport(params: any) {
    return axios.post('/get-support', params);
  },

}