import { PrismicRichText, SliceZone } from '@prismicio/react';
import { Layout, SEO, sliceComponents } from 'components';
import ContactUsFormSection from 'components/contactUsFormSection/contactUsFormSection';
import { graphql } from 'gatsby';
import * as React from 'react';

interface ITeamblePageTemplateProps {
  data: any;
}

const TeamblePageTemplate: React.FunctionComponent<ITeamblePageTemplateProps> = ({ data }) => {

  if (!data) {
    return null;
  }

  const doc = data.prismicTeamblePage.data;

  return (
    <Layout>
      <SEO {...doc.body1[0].primary} />
      <div style={{ backgroundColor: doc.page_background }}>

        {
          doc.page_title.text ? (
            <div className='container pt-40 border-bottom'>
              <PrismicRichText field={doc.page_title.richText} />
            </div>
          ) : null
        }
        {
          doc.show_contact_us_form &&
          <ContactUsFormSection />
        }
        <SliceZone
          slices={doc.body}
          components={sliceComponents}
          defaultComponent={() => null} />
      </div>
    </Layout>
  );
};


export const query = graphql`
  query MyQuery($id: String) {
    prismicTeamblePage(id: {eq: $id}) {
      data {
        page_background
        show_contact_us_form
        page_title {
          richText
          text
        }
        body1 {
          ... on PrismicTeamblePageDataBody1GeneralCard {
            slice_type
            primary {
              description
              title
            }
          }
        }
        body {
          ... on PrismicTeamblePageDataBodyPricingTable {
            slice_type
          }
          ... on PrismicTeamblePageDataBodyFaqSection {
            slice_type
          }
          ... on PrismicTeamblePageDataBodyCallToAction {
            slice_type
          }
          ... on PrismicTeamblePageDataBodyHowItWorksSection {
            slice_type
          }
          ... on PrismicTeamblePageDataBodyAddress {
            slice_type
          }
          ...PricingTableSectionData
          ...FaqSectionData
          ...CallToActionSectionData
          ...TeamblePageHowItWorksSectionData
          ...AddressSectionData
        }
      }
    }
  }
`;


export default TeamblePageTemplate;
