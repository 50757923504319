import * as React from 'react';
import * as contactUsFormSectionClasses from './contactUsFormSection.module.scss';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import api from 'api';

interface IContactUsFormSectionProps {
}

const ContactUsFormSection: React.FunctionComponent<IContactUsFormSectionProps> = () => {


  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter valid email address.')
      .required('Please enter valid email address.'),
    text: yup
      .string()
      .required('Please share your thoughts.'),
  });


  const contactUsForm = useFormik({
    initialValues: {
      email: '',
      text: '',
    },
    validationSchema,
    onSubmit: (values, helpers) => {

      helpers.setSubmitting(true);
      api.getSupport(values).then(
        () => {
          helpers.setSubmitting(false);
          helpers.resetForm();
        },
        () => {
          helpers.setSubmitting(false);
          helpers.resetForm();
        },
      );

    },
  });


  return (
    <section className='py-30 section' >
      <div className='container'>
        <div className="row">
          <div className="col-12 col-lg-6 m-auto">
            <div className={`${contactUsFormSectionClasses.ContactUsForm_Box} p-10`}>
              <h2> Send us a message! </h2>

              <form onSubmit={contactUsForm.handleSubmit}>

                <div className="mb-8">
                  <label htmlFor="formGroupEmailInput" className="form-label">Email *</label>
                  <input
                    type="email"
                    className={`form-control ${contactUsForm.errors.email && contactUsForm.touched.email ? 'is-invalid' : ''}`}
                    id="formGroupEmailInput"
                    placeholder="Please enter your email"
                    {...contactUsForm.getFieldProps('email')} />
                  {
                    contactUsForm.touched.email && contactUsForm.errors.email ?
                      (
                        <div className="invalid-feedback d-block">
                          {contactUsForm.errors.email}
                        </div>
                      ) : null
                  }

                </div>

                <div className="mb-8">
                  <label htmlFor="formGroupTextInput" className="form-label">Message *</label>
                  <textarea
                    placeholder='Share your thoughts'
                    className={`form-control ${contactUsForm.errors.text && contactUsForm.touched.text ? 'is-invalid' : ''}`}
                    id="formGroupTextInput"
                    rows={3}
                    {...contactUsForm.getFieldProps('text')} ></textarea>
                  {
                    contactUsForm.touched.text && contactUsForm.errors.text ?
                      (
                        <div className="invalid-feedback d-block">
                          {contactUsForm.errors.text}
                        </div>
                      ) : null
                  }
                </div>

                <div className='d-flex justify-content-end'>
                  <Button
                    type="submit"
                    disabled={contactUsForm.isSubmitting || !contactUsForm.isValid}>
                    {
                      contactUsForm.isSubmitting ? 'Submitting...' : 'Submit'
                    }
                  </Button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </section >
  );
};

export default ContactUsFormSection;
